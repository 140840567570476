import { Component, OnInit } from "@angular/core";
import { SharedTemplateComponent } from "@ui/shared";

@Component({
    selector: "hw-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"]
})
export class AppComponent extends SharedTemplateComponent implements OnInit {
    constructor() {
        super();
    }
}
