import "reflect-metadata";

type key = string | symbol;

const isAnyObject: (val: unknown) => boolean = (val: unknown) => {
    return val !== null && !Array.isArray(val) && typeof val === "object";
};

const errorText = (description: string, data: object, key?: key, value?: unknown): string => {
    const dataAsString: string = isAnyObject(data) ? JSON.stringify(data) : String(data);

    return `${String(description)};
        Key: ${String(key)}; Value: ${String(value)}; Data: ${dataAsString}`;
};

const getObjectPropertyByString = function (obj: object, path: string, defaultValue: unknown) {
    // https://stackoverflow.com/a/6491621/4604351
    path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    path = path.replace(/^\./, ""); // strip a leading dot
    const props = path.split(".");
    for (let i = 0, n = props.length; i < n; ++i) {
        const currentProp = props[i];
        if (currentProp in obj) {
            obj = obj[currentProp as keyof typeof obj];
        } else {
            return defaultValue;
        }
    }
    return obj;
};

/* FromAny */

export class FromAny {
    from(data: object): this {
        if (typeof data !== "object") {
            throw errorText("Class-from-any error; Data is not object", data);
        }
        const keys = Reflect.ownKeys(this);
        keys.forEach((key) => {
            const propertyName = getPropertyName(this, key);
            const validateFuncs = getValidateFuncs(this, key);
            const convertFunc = getConvertFunc(this, key);
            const defaultValue = getDefaultValue(this, key);
            const childArray = getChildArray(this, key);
            const childObject = getChildObject(this, key);
            const isEqualValue = getIsEqualValue(this, key);

            let val: unknown;

            if (typeof propertyName === "function") {
                val = propertyName(data);
            } else {
                const propertyNames = (Array.isArray(propertyName) ? propertyName : [propertyName]).reverse();
                propertyNames.forEach((currentPropertyName) => {
                    const currentVal = getObjectPropertyByString(data, String(currentPropertyName), undefined);
                    if (currentVal !== undefined) {
                        val = currentVal;
                    }
                });
            }
            if (defaultValue !== undefined) {
                val = val !== undefined ? val : defaultValue;
            }
            val = convertFunc(val);

            val =
                childArray && Array.isArray(val)
                    ? val.map((arrayElement) => {
                          if (isAnyObject(arrayElement)) {
                              return new childArray().from(arrayElement as object);
                          } else {
                              throw errorText("Class-from-any error; Child element is not object", data, key, arrayElement);
                          }
                      })
                    : val;

            val = childObject && isAnyObject(val) ? new childObject().from(val as object) : val;

            validateFuncs.forEach((func) => {
                if (!func(val)) {
                    throw errorText(`Class-from-any error; Validate function ${func.name} fail`, data, key, val);
                }
            });

            if (isEqualValue !== undefined) {
                if (isEqualValue !== val) {
                    throw errorText(
                        `Class-from-any error; The value of the ${String(key)} property must be equal to ${String(isEqualValue)}`,
                        data,
                        key,
                        val
                    );
                }
            }
            if (val !== undefined) {
                this[key as keyof FromAny] = val as any;
            }            
        });
        return this;
    }

    stringify(): string {
        const replacer = (key: string, value: unknown) => {
            const stringifyFunc = getStringifyFunc(this, key);
            if (stringifyFunc) {
                return stringifyFunc(value);
            }
            return value;
        };
        return JSON.stringify(this, replacer);
    }
}

/* GetFrom */

type getFromValue = key | key[] | ((data: any) => unknown);

export const GetFrom = (propertyName: getFromValue) => {
    return Reflect.metadata(getFromMetadataKey, propertyName);
};

const getFromMetadataKey = "GetFrom";

const getPropertyName = (fromAnyInstance: FromAny, key: key): getFromValue => {
    const propertyName = Reflect.getMetadata(getFromMetadataKey, fromAnyInstance, key) as getFromValue | undefined;
    return propertyName ? propertyName : key;
};

/* Validate */

export const Validate = (...validateFunc: validateFunc[]) => {
    return Reflect.metadata(validateMetadataKey, validateFunc);
};

type validateFunc = (val: unknown) => boolean;
const validateMetadataKey = "Validate";

const getValidateFuncs = (fromAnyInstance: FromAny, key: key): validateFunc[] => {
    const funcs = Reflect.getMetadata(validateMetadataKey, fromAnyInstance, key) as validateFunc[] | undefined;
    return funcs ? funcs : [];
};

/* Child object */

export const ChildObject = (childClass: typeof FromAny) => {
    return Reflect.metadata(childObjectMetadataKey, childClass);
};

const childObjectMetadataKey = "ChildObject";

const getChildObject = (fromAnyInstance: FromAny, key: key): typeof FromAny | undefined => {
    return Reflect.getMetadata(childObjectMetadataKey, fromAnyInstance, key) as typeof FromAny | undefined;
};

/* Child array */

export const ChildArray = (childClass: typeof FromAny) => {
    return Reflect.metadata(childArrayMetadataKey, childClass);
};

const childArrayMetadataKey = "ChildArray";

const getChildArray = (fromAnyInstance: FromAny, key: key): typeof FromAny | undefined => {
    return Reflect.getMetadata(childArrayMetadataKey, fromAnyInstance, key) as typeof FromAny | undefined;
};

/* Convert */

export const Convert = (converter: converterFunc) => {
    return Reflect.metadata(convertMetadataKey, converter);
};

type converterFunc = (val: any) => any;
const convertMetadataKey = "Convert";

const getConvertFunc = (fromAnyInstance: FromAny, key: key): converterFunc => {
    const func = Reflect.getMetadata(convertMetadataKey, fromAnyInstance, key) as converterFunc | undefined;
    return func ? func : (val) => val;
};

/* DefaultValue */

export const DefaultValue = (value: unknown) => {
    return Reflect.metadata(defaultValueMetadataKey, value);
};

const defaultValueMetadataKey = "Value";

const getDefaultValue = (fromAnyInstance: FromAny, key: key): unknown => {
    return Reflect.getMetadata(defaultValueMetadataKey, fromAnyInstance, key) as unknown;
};

/* IsEqual */

export const IsEqual = (isEqualFunc: unknown) => {
    return Reflect.metadata(isEqualMetadataKey, isEqualFunc);
};

const isEqualMetadataKey = "IsEqual";

const getIsEqualValue = (fromAnyInstance: FromAny, key: key): unknown => {
    return Reflect.getMetadata(isEqualMetadataKey, fromAnyInstance, key) as unknown;
};

/* Stringify */

export const Stringify = (stringifyFunc: stringifyFunc) => {
    return Reflect.metadata(stringifyMetadataKey, stringifyFunc);
};

type stringifyFunc = (val: any) => any;
const stringifyMetadataKey = "Stringify";

const getStringifyFunc = (fromAnyInstance: FromAny, key: key): stringifyFunc | undefined => {
    return Reflect.getMetadata(stringifyMetadataKey, fromAnyInstance, key) as stringifyFunc | undefined;
};

/* SkipStringify */

export const SkipStringify = () => {
    return Reflect.metadata(stringifyMetadataKey, () => {return});
};

