import { NgDompurifySanitizer } from "@tinkoff/ng-dompurify";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TuiRootModule, TuiDialogModule, TuiNotificationModule, TUI_SANITIZER, TuiLoaderModule } from "@taiga-ui/core";
import { NgModule, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { environment } from "../environments/environment";
import { appEnvToken } from "@services/env";
import { disableAnimations } from "@ui/animation";
import * as Sentry from "@sentry/angular-ivy";

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule.withConfig({ disableAnimations }),
        AppRoutingModule,
        HttpClientModule,
        TuiRootModule,
        TuiDialogModule,
        TuiNotificationModule,
        TuiLoaderModule
    ],
    providers: [
        { provide: TUI_SANITIZER, useClass: NgDompurifySanitizer },
        { provide: appEnvToken, useValue: environment },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false
            })
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
