export { YoutubeCategories, YoutubeCategory, youtubeCategories, getYoutubeVideoCategoryById, defaultYoutubeCategory };

class YoutubeCategory {
    id: number;
    title: string;
}

class YoutubeCategories {
    items: YoutubeCategory[];
}

const youtubeCategories: YoutubeCategories = {
    items: [
        {
            id: 1,
            title: "Film & Animation"
        },
        {
            id: 2,
            title: "Autos & Vehicles"
        },
        {
            id: 10,
            title: "Music"
        },
        {
            id: 15,
            title: "Pets & Animals"
        },
        {
            id: 17,
            title: "Sports"
        },
        {
            id: 18,
            title: "Short Movies"
        },
        {
            id: 19,
            title: "Travel & Events"
        },
        {
            id: 20,
            title: "Gaming"
        },
        {
            id: 21,
            title: "Videoblogging"
        },
        {
            id: 22,
            title: "People & Blogs"
        },
        {
            id: 23,
            title: "Comedy"
        },
        {
            id: 24,
            title: "Entertainment"
        },
        {
            id: 25,
            title: "News & Politics"
        },
        {
            id: 26,
            title: "Howto & Style"
        },
        {
            id: 27,
            title: "Education"
        },
        {
            id: 28,
            title: "Science & Technology"
        },
        {
            id: 29,
            title: "Nonprofits & Activism"
        },
        {
            id: 30,
            title: "Movies"
        },
        {
            id: 31,
            title: "Anime/Animation"
        },
        {
            id: 32,
            title: "Action/Adventure"
        },
        {
            id: 33,
            title: "Classics"
        },
        {
            id: 34,
            title: "Comedy"
        },
        {
            id: 35,
            title: "Documentary"
        },
        {
            id: 36,
            title: "Drama"
        },
        {
            id: 37,
            title: "Family"
        },
        {
            id: 38,
            title: "Foreign"
        },
        {
            id: 39,
            title: "Horror"
        },
        {
            id: 40,
            title: "Sci-Fi/Fantasy"
        },
        {
            id: 41,
            title: "Thriller"
        },
        {
            id: 42,
            title: "Shorts"
        },
        {
            id: 43,
            title: "Shows"
        },
        {
            id: 44,
            title: "Trailers"
        }
    ]
};

const defaultYoutubeCategory = 27;

const getYoutubeVideoCategoryById = (categoryId: number | string): YoutubeCategory => {
    const category = youtubeCategories.items.find((c) => c.id === categoryId || String(c.id) === categoryId);
    if (category === undefined) {
        throw new Error("Can not find category");
    }
    return category;
};
