import { errorText } from "@utils/errors";

export class Env<T extends object> {
    variables: T;

    constructor(variables: T) {
        this.variables = variables;
    }

    get<K extends keyof T>(key: K): T[K] {
        if (!(key in this.variables)) {
            throw errorText(`Env value ${String(key)} not found`);
        }
        return this.variables[key];
    }

}
