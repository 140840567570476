import { stringify } from "javascript-stringify";

export {
    errorText
}

const errorText = (...params: unknown[]): string => {
    return params.map((val) => {        
        return (typeof val === "string") ? val : stringify(val);
    }).join("\n");
};

