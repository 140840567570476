import { Subject } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";

@Component({
    template: ""
})
export class SharedTemplateComponent implements OnInit, OnDestroy {
    protected readonly ngUnsubscribe: Subject<void> = new Subject<void>();

    protected constructor() {
        // Класс можно только наследовать
    }

    /* eslint-disable */
    ngOnInit() {}
    /* eslint-enable */

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }
}
