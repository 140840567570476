import { SentenceModel } from "@interface/nlp";
import { YoutubeVideo, YoutubeChannel } from "@interface/youtube";

export { DictionaryYoutubeVideoSentence, DictionaryYoutubeVideoModel, DictionaryYoutubeChannelItem, availableDictionaryYoutubeCategories };

class DictionaryYoutubeVideoSentence extends SentenceModel {
    captionStartMs: number; // Milliseconds
}

class DictionaryYoutubeVideoModel extends YoutubeVideo {
    sentences: DictionaryYoutubeVideoSentence[];
    durationMs: number; // Milliseconds
    durationOfTextEventsMs: number; // Milliseconds
    countWords: number;
}

class DictionaryYoutubeChannelItem extends YoutubeChannel {}

const availableDictionaryYoutubeCategories = [27, 24, 22, 28, 25] as const;

