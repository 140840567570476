import { Inject, Injectable, InjectionToken } from "@angular/core";
import { Env } from "@utils/env";

export { appEnvToken };

const appEnvToken = new InjectionToken("Env token");

@Injectable({
    providedIn: "root"
})
export class EnvService<T extends object> extends Env<T> {
    constructor(@Inject(appEnvToken) variables: T) {
        super(variables);
    }
}
