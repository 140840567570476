import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { YoutubeListenRoutingClass } from "@dictionary/interface";

const routes: Routes = [
    {
        path: "",
        loadChildren: () => import("./pages/start/start.module").then((m) => m.StartPageModule)
    },
    {
        path: YoutubeListenRoutingClass.pathTemplate,
        loadChildren: () => import("./pages/youtube/youtube.module").then((m) => m.YoutubePageModule)
    },
    {
        path: "**",
        loadChildren: () =>
            new Promise(() => {
                if (window.location.href.match(/404/)) {
                    return;
                }
                window.location.href = "/404/";
            })
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule]
})
export class AppRoutingModule {}
